import ru from "./ru"
import en from "./en"
import uk from "./uk"
import it from "./it"
import es from "./es"

export default {
    ru,
    en,
    uk,
    it,
    es
}
