export default {
    account_restored: "Обліковий запис відновлено",
    restore_account: "Відновити аккаунт",
    authorization_platform: "Увійти або створити акаунт",
    on_your: "На Вашу",
    email: "ел. адресу",
    code_has_been_sent: "було надіслано код підтвердження",
    enter_code: "Введіть код",
    code_is_valid: "Код дійсний",
    minutes: "хвилин",
    request_new_code: "Ви зможете запросити код ще раз через",
    sec: "сек",
    send_again: "Надіслати код повторно",
    back: "Назад",
    next: "Далі",
    enter_password: "Введіть пароль",
    repeat_password: "Повторіть пароль",
    forgot_password: "Забули пароль?",
    restore_access: "Відновити доступ",
    enter_username: "Введіть адресу ел. пошти або телефон",
    enter_email: "Введіть адресу ел. пошти",
    create_new_password: "Придумайте новий пароль",
    select_language: "Оберіть мову",
    select_currency: "Оберіть валюту",
    language: "Мова",
    currency: "Валюта",
    or: "або",
    enter: "Увійти",
    close: "Закрити",
    fields_filled: "Поля заповнені!",
    fill_title: "Заповніть поля.",
    fill_descr: "Для завершення реєстрації необхідно Заповнити поля",
    welcome: "Вітаємо!",
    check_locale: "Підкажіть, чи правильно ми визначили Вашу мову:",
    all_ok: "Так все вірно",
    no_change_language: "Ні, змінити мову",
    set_password: "Встановлення пароля",
    set_password_description: "Придумайте пароль",
    understand_thanks: "Зрозумів, дякую!",
    prerecording: "Попередній запис",
    prerecording_success: "Ви успішно додані до списку попереднього запису",
    congratulations: "Вітаємо!",
    back_to_menu: "Повернутись в <b>Меню</b>",
    //
    partners_program: "Партнерська програма",
    become_a_partner: "Стати партнером",
    products: "Мої продукти",
    certificates: "Сертифікати",
    knowledge: "База знань",
    practices: "Пройдені практики",
    dictionary: "Словник",
    calendar: "Календар подій",
    news: "Новини",
    history: "Історія покупок",
    localization: "Мова",
    settings: "Налаштування",
    exit: "Вихід",
    projects: "Мої проекти",
    "expert.profile.expert_organization_cabinet": "Кабінет організатора",
    "expert.profile.expert_producer_cabinet": "Кабінет продюсера",
    "expert.profile.expert_cabinet": "Кабінет експерта",
    "expert.profile.student_cabinet": "Кабінет учня",
    "expert.profile.create_cabinet": "Створити кабінет",
    subaccounts: "Саб-акаунти",
    school: "Відкрити свою школу",
    dashboard: "Дашбоард",
    student_dashboard: "Моє навчання",
    logout: "Вийти з облікового запису"
}
