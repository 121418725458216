export default {
    account_restored: "Account restored",
    restore_account: "Recover account",
    authorization_platform: "Login or register",
    on_your: "To your",
    email: "email address",
    code_has_been_sent: "confirmation code has been sent",
    enter_code: "Enter code",
    code_is_valid: "Code is valid",
    minutes: "minutes",
    request_new_code: "You can request the code again in",
    sec: "sec",
    send_again: "Send code again",
    back: "Back",
    next: "Next",
    enter_password: "Enter password",
    repeat_password: "Repeat password",
    forgot_password: "Forgot your password?",
    restore_access: "Restore access",
    enter_username: "Enter your email address or phone",
    enter_email: "Enter your email address",
    create_new_password: "Create a new password",
    select_language: "Choose language",
    select_currency: "Choose currency",
    language: "Language",
    currency: "Currency",
    or: "or",
    enter: "Enter",
    close: "Close",
    fields_filled: "Fields filled!",
    fill_title: "Fill the fields.",
    fill_descr: "To complete the registration, you must Fill in the fields",
    welcome: "Welcome!",
    check_locale: "Tell me if we have correctly identified your language:",
    all_ok: "Yes, that's right",
    no_change_language: "No, change the language",
    set_password: "Setting a password",
    set_password_description: "Pick a password",
    understand_thanks: "Understood, thanks!",
    prerecording: "Pre-registration",
    prerecording_success: "You have been successfully added to the pre-registration list",
    congratulations: "Congratulations!",
    back_to_menu: "Back to <b>Menu</b>",
    //
    partners_program: "Affiliate program",
    become_a_partner: "Become a partner",
    products: "My products",
    certificates: "Certificates",
    knowledge: "Knowledge Base",
    practices: "Completed practices",
    dictionary: "Dictionary",
    calendar: "Calendar of events",
    news: "News",
    history: "Purchase history",
    localization: "Language",
    settings: "Settings",
    exit: "Exit",
    projects: "My projects",
    "expert.profile.expert_organization_cabinet": "Organization Cabinet",
    "expert.profile.expert_producer_cabinet": "Producer Cabinet",
    "expert.profile.expert_cabinet": "Expert Cabinet",
    "expert.profile.student_cabinet": "Student Cabinet",
    "expert.profile.create_cabinet": "Create an account",
    subaccounts: "Sub-accounts",
    school: "Open your own school",
    dashboard: "Dashboard",
    student_dashboard: "Dashboard",
    logout: "Log out of your account"
}
