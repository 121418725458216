export default {
    account_restored: "Аккаунт восстановлен",
    restore_account: "Восстановить аккаунт",
    authorization_platform: "Войти или создать аккаунт",
    on_your: "На Ваш",
    email: "эл. адрес",
    code_has_been_sent: "был отправлен код подтверждения",
    enter_code: "Введите код",
    code_is_valid: "Код действителен",
    minutes: "минут",
    request_new_code: "Вы сможете запросить код повторно через",
    sec: "сек",
    send_again: "Отправить код повторно",
    back: "Назад",
    next: "Далее",
    enter_password: "Введите пароль",
    repeat_password: "Введите пароль",
    forgot_password: "Забыли пароль?",
    restore_access: "Восстановить доступ",
    enter_username: "Введите адрес эл. почты или телефон",
    enter_email: "Введите адрес эл. почты",
    create_new_password: "Придумайте новый пароль",
    select_language: "Выберите язык",
    select_currency: "Выберите валюту",
    language: "Язык",
    currency: "Валюта",
    or: "или",
    enter: "Войти",
    close: "Закрыть",
    fields_filled: "Поля заполнены!",
    fill_title: "Заполните поля",
    fill_descr: "Для завершения регистрации необходимо Заполнить поля",
    welcome: "Приветствуем!",
    check_locale: " Подскажите, правильно ли мы определили Ваш язык:",
    all_ok: "Да, все верно",
    no_change_language: "Нет, сменить язык",
    set_password: "Установка пароля",
    set_password_description: "Придумайте пароль",
    understand_thanks: "Понял, спасибо!",
    prerecording: "Предварительная запись",
    prerecording_success: "Вы добавлены в список предварительной записи",
    congratulations: "Поздрвляем!",
    back_to_menu: "Назад в <b>Меню</b>",
    //
    partners_program: "Партнерская программа",
    become_a_partner: "Стать партнером",
    products: "Мои продукты",
    certificates: "Сертификаты",
    knowledge: "База знаний",
    practices: "Пройденные практики",
    dictionary: "Словарь",
    calendar: "Календарь событий",
    news: "Новости",
    history: "История покупок",
    localization: "Язык",
    settings: "Настройки",
    exit: "Выход",
    projects: "Мои проекты",
    "expert.profile.expert_organization_cabinet": "Кабинет организатора",
    "expert.profile.expert_producer_cabinet": "Кабинет продюсера",
    "expert.profile.expert_cabinet": "Кабинет эксперта",
    "expert.profile.student_cabinet": "Кабинет ученика",
    "expert.profile.create_cabinet": "Создать кабинет",
    subaccounts: "Саб-аккаунты",
    school: "Открыть свою школу",
    dashboard: "Дашбоард",
    student_dashboard: "Моё обучение",
    logout: "Выйти из аккаунта"
}
